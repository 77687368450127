import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap'
import { useEffect } from 'react';
import manPower from '../assets/Our Services/ManPower-removebg-preview.png'
import construction from '../assets/Our Services/Construction-removebg-preview.png'
import equipments from '../assets/Our Services/Equipments-removebg-preview.png'
import fabrication from '../assets/Our Services/Fabrication-removebg-preview.png'
import tele from '../assets/Our Services/Telecommunication-removebg-preview.png'
import safety from '../assets/Our Services/Saftey-removebg-preview.png'
import air from '../assets/Our Services/Air_Conditioner_Repair-removebg-preview.png'
import Con from '../assets/Our Services/Concrete-removebg-preview.png'
import industrial from '../assets/Our Services/Industrial_Material-removebg-preview.png'
import about from '../assets/about_us-transformed.png'
import $ from 'jquery'
import TextAnim from '../TextAnim.jsx';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import pic1 from '../assets/Logos/Picture1.png'
import pic2 from '../assets/Logos/Picture4.png'
import pic3 from '../assets/Logos/Picture5.png'
import pic4 from '../assets/Logos/Picture6.png'   
import pic5 from '../assets/Logos/Picture7.png'
import pic6 from '../assets/Logos/Picture8.png'
import pic7 from '../assets/Logos/Picture9.png'
import pic8 from '../assets/Logos/Picture10.png'
import pic9 from '../assets/Logos/Picture11.png'
import pic10 from '../assets/Logos/Picture12.png'
import pic11 from '../assets/Logos/Picture13.png'
import pic12 from '../assets/Logos/Picture14.png'
import pic13 from '../assets/Logos/Picture15.png'
import pic14 from '../assets/Logos/Picture16.jpg'
import pic15 from '../assets/Logos/Picture17.jpg'
import pic16 from '../assets/Logos/Picture18.png'
import pic17 from '../assets/Logos/Picture19.png'
import pic18 from '../assets/Logos/Picture20.png'
import pic19 from '../assets/Logos/Picture21.png'
import pic20 from '../assets/Logos/Picture22.png'
import pic21 from '../assets/Logos/Picture23.png'
import pic22 from '../assets/Logos/Picture24.png'
import pic23 from '../assets/Logos/Picture25.png'
import pic24 from '../assets/Logos/Picture26.png'
import pic25 from '../assets/Logos/Picture27.jpg'
import pic26 from '../assets/Logos/Picture28.jpg'
import pic27 from '../assets/Logos/Picture29.png'
import pic28 from '../assets/Logos/Picture30.png'
import pic29 from '../assets/Logos/Picture31.png'
import pic30 from '../assets/Logos/Picture32.png'



export default function Home(){
  const nav = useNavigate()


    useEffect(()=>{
     
      const slides = document.querySelectorAll('.slide');
      const next = document.querySelector('#next');
      const prev = document.querySelector('#prev');
      const auto = false; // Auto scroll
      const intervalTime = 5000;
      let slideInterval;
      
      const nextSlide = () => {
        // Get current class
        const current = document.querySelector('.current');
        // Remove current class
        current.classList.remove('current');
        // Check for next slide
        if (current.nextElementSibling) {
          // Add current to next sibling
          current.nextElementSibling.classList.add('current');
        } else {
          // Add current to start
          slides[0].classList.add('current');
        }
        setTimeout(() => current.classList.remove('current'));
      };
      
      const prevSlide = () => {
        // Get current class
        const current = document.querySelector('.current');
        // Remove current class
        current.classList.remove('current');
        // Check for prev slide
        if (current.previousElementSibling) {
          // Add current to prev sibling
          current.previousElementSibling.classList.add('current');
        } else {
          // Add current to last
          slides[slides.length - 1].classList.add('current');
        }
        setTimeout(() => current.classList.remove('current'));
      };
      
      // Button events
      next.addEventListener('click', e => {
        nextSlide();
        if (auto) {
          clearInterval(slideInterval);
          slideInterval = setInterval(nextSlide, intervalTime);
        }
      });
      
      prev.addEventListener('click', e => {
        prevSlide();
        if (auto) {
          clearInterval(slideInterval);
          slideInterval = setInterval(nextSlide, intervalTime);
        }
      });
      
      // Auto slide
      if (auto) {
        // Run next slide at interval time
        slideInterval = setInterval(nextSlide, intervalTime);
      }
    },[])

    function navigate(v){
      nav(v)
      nav(0)
    }
    return(
      
  <div>
{/* CAROUSEL */}
      <div class="slider">
        <div class="slide current">
          <div class="content">
            <h1>Construction</h1>
            <p>
            Building futures, one project at a time, with expertise and innovation.
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Fabrication</h1>
            <p>
            Crafting excellence with precision steel solutions, setting industry standards.
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Telecommunication</h1>
            <p>
            Connecting communities with cutting-edge telecom solutions, bridging the digital divide.
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Safety</h1>
            <p>
            Prioritizing safety for every task, ensuring peace of mind for all stakeholders.
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Equipments</h1>
            <p>
            Access the pinnacle of efficiency with our top-tier equipment fleet and seamless service.
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Manpower</h1>
            <p>
            Empowering projects worldwide with skilled professionals and unmatched reliability.
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Air Conditioner Repair & Service</h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit hic
              maxime, voluptatibus labore doloremque vero!
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Industrial Material supply</h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit hic
              maxime, voluptatibus labore doloremque vero!
            </p>
          </div>
        </div>
        <div class="slide">
          <div class="content">
            <h1>Concrete Cooling Chillers and Ice Plant</h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit hic
              maxime, voluptatibus labore doloremque vero!
            </p>
          </div>
        </div>
      </div>
    
    <div class="buttons">
      <button id="prev"><i class="fas fa-arrow-left"></i></button>
      <button id="next"><i class="fas fa-arrow-right"></i></button>
    </div>

    {/* ABOUT US */}

<div id='abtus' style={{width:'100%',height:'400px',display:'flex', backgroundColor:'#FAF9F6',color:'black',marginTop:'100px'}}>



     
        <div style={{flex:'60%', display:'flex',flexDirection:'column',marginTop:'50px',paddingLeft:'50px',paddingRight:'50px'}}>
          <h1 style={{marginBottom:'30px'}}>About Us</h1>
          
          <p>Jazaco our partner established in the year 1996, Push Engineering works are leading organization engaged in Design,
          manufacturing, exporting and supplying wide range of products like Flake Ice Plant, Tube Ice Plant, Flake Ice Machine,
          Tube Ice Machine, Chilled water plant, Automatic Ice Handling System, Ice Storage Room, Pressure Vessel package,
          Hear Exchanger, Screw Conveyor system for Ice conveying, Pneumatic System for Ice Conveying,Oil Separator, Day
          Tank etc. We evolved as a group offering total solutions that ranges from Concept to Commissioning, Setting the trend
                  for high quality and customized solution at competitive prices.</p>
        </div>

      <div id='abtus-img' style={{backgroundImage:`url(${about})`, backgroundRepeat:'no-repeat', backgroundSize:'cover',backgroundPosition:'center center',flex:'40%'}}>

      </div>
      
   </div>

   <div style={{height: '100%', width: '100vw', display: 'flex',flexDirection:'column',marginTop:'170px', justifyContent: 'center', alignItems: 'center'}}>
   <div className="text-center mb-5 col-md-10 mx-auto">
      <h2 className="font-weight-bold display-4 ">Our <span style={{color: '#FA9823'}}>Work</span></h2>
      <p className="mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi asperiores dolore, dolorum
          error eveniet molestiae necessitatibus officiis possimus praesentium quas.</p>
    </div>
      <div class="" style={{backgroundColor: 'white'}}>
          <div class="slidern">
              <div class="slide-track">
              <div class="sliden"><img alt='img' width={130}   src={pic1}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic2}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic3}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic4}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic5}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic6}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic7}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic8}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic9}/></div>


              </div>
          </div>
          <div class="slidern">
              <div class="slide-track">
              <div class="sliden"><img alt='img' width={130}  src={pic13}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic14}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic15}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic16}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic17}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic18}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic19}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic20}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic21}/></div>

         
              </div>
          </div>


          <div class="slidern">
              <div class="slide-track">
              <div class="sliden"><img alt='img' width={130}  src={pic25}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic26}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic27}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic23}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic24}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic11}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic12}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic22}/></div>
              <div class="sliden"><img alt='img' width={130}  src={pic10}/></div>



              </div>
          </div>

          {/* <div class="slider">
              <div class="slide-track">
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fburjuman.png?alt=media&token=92b763ca-a46e-4b7b-bb34-a95744d6e0ff"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fmajidaf.png?alt=media&token=fb900a75-ab2d-4820-bba0-f9bc9321fbe5"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fdubaiwtc.png?alt=media&token=93ac2c3e-6683-4cdd-bac6-d4888613c14b"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fsharjahchamber.png?alt=media&token=243de9d3-c50e-4e97-8cc3-a734a7dc511e"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fbayanat.png?alt=media&token=bf5660fc-046d-4223-b475-327c931f3fa2"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fgoogle.png?alt=media&token=1af803cc-ca25-408d-9e35-031fcae8aea2"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fitp.png?alt=media&token=e3a2ffeb-b84f-4ce8-908a-446e3194b8d7"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fsladiesc.png?alt=media&token=e5d648e4-5057-499d-8515-cdff3e5ebc40"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fadnec.png?alt=media&token=31cb505b-a6ba-4424-a3d2-28ba5ac59d36"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fcerner.png?alt=media&token=8205f9ab-1dd0-4a52-9bc0-a9f23f98a508"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fnakheel.png?alt=media&token=57da095f-77ab-40d4-a252-c0e7b7e5f1f4"/></div>
              <div class="sliden"><img alt='img' width={130}  src="https://firebasestorage.googleapis.com/v0/b/thh-wix-assets.appspot.com/o/Client%20Logos%2FRow3%2Fkhalifauni.png?alt=media&token=14fffb66-716e-4b81-939b-33167554adb6"/></div>
              </div>
          </div> */}
      </div>

      </div>




{/* Our Services temp 1  */}
       <div className="">
    {/* <section>
        <div className="text-center mb-5 col-md-10 mx-auto">
            <h2 className="font-weight-bold display-4 ">Our <span style={{color: '#FA9823'}}>Services</span></h2>
            <p className="mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi asperiores dolore, dolorum
                error eveniet molestiae necessitatibus officiis possimus praesentium quas.</p>
        </div>
        <div className="row">
            <div className="col-md-4 my-4 ">
                <a href="#" className="stretched-link text-dark" style={{textDecoration:'none'}}>
                    <div className="px-3 py-5">
                        <span className="text-uppercase">Services</span>
                        <h3 className="text-capitalize my-2" style={{fontWeight: '600'}}>Enter Your Headline Here</h3>
                        <button className="btn my-4 px-4 py-2 rounded-pill text-white" style={{backgroundColor: '#FA9823'}}>See More</button>
                    </div>
                </a>
            </div>
            <div className="col-md-4 my-4 ">
                <a href="#" className="stretched-link text-dark" style={{textDecoration:'none'}}>
                    <div className="px-3 py-5 bg-light">
                        <div className="font-weight-bold circle rounded-circle d-flex align-items-center justify-content-center mx-auto my-3"
                             style={{width: '60px' ,height: '60px'}}>
                        </div>
                        <div className="px-3 text-center pb-3">
                            <h5 className="text-uppercase" style={{fontWeight: '600'}}>Services</h5>
                            <p className="font-weight-light my-3">Lorem ipsum dolor sit consectetur adipisicing elit. Alias amet
                                deleniti et fugit iusto nesciunt.</p>
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-md-4 my-4 ">
                <a href="#" className="stretched-link text-dark" style={{textDecoration:'none'}}>
                    <div className="px-3 py-5 text-white" style={{backgroundColor: '#FA9823'}}>
                        <div className="font-weight-bold circle rounded-circle d-flex align-items-center justify-content-center mx-auto my-3"
                             style={{width: '60px', height: '60px'}}>
                            
                        </div>
                        <div className="px-3 text-center pb-3">
                            <h5 className="text-uppercase" style={{fontWeight: '600'}}>Services</h5>
                            <p className="font-weight-light my-3">Lorem ipsum dolor sit consectetur adipisicing elit. Alias amet
                                deleniti et fugit iusto nesciunt.</p>
                        </div>
                    </div>
                </a>
            </div>

        </div>
    </section> */}


  {/* meet out team */}

  {/* <div class="container mobteam">
   <div class="row">

     <div className="text-center mb-5 col-md-10 mx-auto">
                <h2 className="font-weight-bold display-4 ">Our <span style={{color: '#FA9823'}}>Team</span></h2>
                <p className="mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi asperiores dolore, dolorum
                    error eveniet molestiae necessitatibus officiis possimus praesentium quas.</p>
            </div> 

          <div class="col-lg-4 col-md-6">
          <div class="team__item set-bg" style={{backgroundImage: `url('https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80')`}}>
            <div class="team__text">
              <div class="team__title">
                <h4>Furkan Giray</h4>
                <span>Web Developer</span>
              </div>
              <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                porta leo, non tincidunt mauris condimentu eget. Vivamus non turpis elit. Aenean
                ultricies nisl sit amet.</p>
              <div class="team__social">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="team__item set-bg" style={{backgroundImage: `url('https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80')`}}>
            <div class="team__text">
              <div class="team__title">
                <h4>Furkan Giray</h4>
                <span>Web Developer</span>
              </div>
              <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                porta leo, non tincidunt mauris condimentu eget. Vivamus non turpis elit. Aenean
                ultricies nisl sit amet.</p>
              <div class="team__social">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="team__item set-bg" style={{backgroundImage: `url('https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80')`}}>
            <div class="team__text">
              <div class="team__title">
                <h4>Furkan Giray</h4>
                <span>Web Developer</span>
              </div>
              <p>Vestibulum dapibus odio quam, sit amet hendrerit dui ultricies consectetur. Ut viverra
                porta leo, non tincidunt mauris condimentu eget. Vivamus non turpis elit. Aenean
                ultricies nisl sit amet.</p>
              <div class="team__social">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div> 
  </div> */}


      {/* Our Services Temp2  */}
    <section class="we-offer-area text-center ">
        <div class="container">
        <div className="text-center mb-5 col-md-10 mx-auto">
            <h2 className="font-weight-bold display-4 ">Our <span style={{color: '#FA9823'}}>Services</span></h2>
            <p className="mx-5">Providing top-quality products and services since 1996, we lead the industry with a diverse range of offerings, setting trends and ensuring competitive pricing</p>
        </div>
                <div class="row our-offer-items less-carousel">
                  
                    <div onClick={()=>{navigate('/Manpower')}}  class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                            {/* <i class="fas fa-pen-fancy"></i> */}
                            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={manPower} alt='ManPower' style={{width:'150px'}}/>
                            </div>
 
                            <h4>Manpower</h4>
                            <p>
                            Empowering projects worldwide with skilled professionals and unmatched reliability.
                            </p>
                        </div>
                    </div>
                    

                    
                    <div onClick={()=>{navigate('/Safety')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                          <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={safety} alt='ManPower' style={{width:'150px'}}/>
                          </div>
                            <h4>Safety</h4>
                            <p>
                            Prioritizing safety for every task, ensuring peace of mind for all stakeholders.
                            </p>
                        </div>
                    </div>
                    

                    
                    <div onClick={()=>{navigate('/Telecom')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                          <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={tele} alt='ManPower' style={{height:'120px'}}/>
                          </div>
                            <h4>Telecommunication</h4>
                            <p>
                            Connecting communities with cutting-edge telecom solutions, bridging the digital divide.
                            </p>
                        </div>
                    </div>
                    

                    
                    <div onClick={()=>{navigate('/Fabrication')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                          <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={fabrication} alt='ManPower' style={{height:'130px'}}/>
                          </div>
                            <h4>Fabrication</h4>
                            <p>
                            Crafting excellence with precision steel solutions, setting industry standards.
                            </p>
                        </div>
                    </div>
                    

                    
                    <div class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                          <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={equipments} alt='ManPower' style={{width:'125px'}}/>
                          </div>
                            <h4>Equipments</h4>
                            <p>
                            Access the pinnacle of efficiency with our top-tier equipment fleet and seamless service.
                            </p>
                        </div>
                    </div>
                    

                    
                    <div onClick={()=>{navigate('/Construction')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        < div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={construction} alt='ManPower' style={{width:'150px'}}/>
                          </div>
                            <h4>Construction</h4>
                            <p>
                            Building futures, one project at a time, with expertise and innovation.
                            </p>
                        </div>
                    </div>

                    <div onClick={()=>{navigate('/Construction')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        < div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={air} alt='ManPower' style={{width:'128px'}}/>
                          </div>
                            <h4>Concrete Cooling Chillers and Ice Plant</h4>
                            <p>
                            Keeping projects cool and efficient with state-of-the-art concrete cooling solutions and ice plant services.
                            </p>
                        </div>
                    </div>

                    <div onClick={()=>{navigate('/Construction')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        < div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={Con} alt='ManPower' style={{width:'150px'}}/>
                          </div>
                            <h4>Air Conditioner Repair & Service</h4>
                            <p>
                            Beat the heat with our expert air conditioner repair and service, ensuring comfort and reliability year-round.
                            </p>
                        </div>
                    </div>

                    <div onClick={()=>{navigate('/Construction')}} class="col-md-4 col-sm-6 equal-height">
                        <div class="item">
                        < div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                              <img src={industrial} alt='ManPower' style={{width:'160px'}}/>
                          </div>
                            <h4>Industrial Material supply</h4>
                            <p>
                            Your one-stop destination for industrial material needs, delivering quality supplies for seamless operations and unmatched productivity.
                            </p>
                        </div>
                    </div>
                    
                </div>
        </div>
    </section> 


    {/* Our Work Temp 1 */}
    <div>
  
    <div className="text-center mb-5 col-md-10 mx-auto">
      <h2 className="font-weight-bold display-4 ">Our <span style={{color: '#FA9823'}}>Work</span></h2>
      <p className="mx-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi asperiores dolore, dolorum
          error eveniet molestiae necessitatibus officiis possimus praesentium quas.</p>
    </div>

    {/* <div class="container">
      <div class="card">
        <img src="https://pibig.info/uploads/posts/2021-06/1623123014_3-pibig_info-p-znachenie-vodi-v-prirode-priroda-krasivo-f-3.jpg" alt="Animated Card Hover Effect Html & CSS"/>
        <div class="overlay">
          <h1 class="text-h1" style={{color:'black'}}>the mountains</h1>
          <p class="text-p" style={{color:'black'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias dolores ex expedita quaerat a in et.
          </p>
          <a class="link-a" href="#" style={{textDecoration:'none',background:'#FA9823',color: 'white'}}> Read More</a>
        </div>
      </div>

      <div class="card">
        <img src="https://sportishka.com/uploads/posts/2022-03/thumbs/1647169883_1-sportishka-com-p-prozrachnii-led-baikala-turizm-krasivo-fot-1.jpg" alt="Animated Card Hover Effect Html & CSS"/>
        <div class="overlay">
          <h1 class="text-h1"  style={{color:'black'}}>the mountains</h1>
          <p class="text-p"  style={{color:'black'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias dolores ex expedita quaerat a in et.
          </p>
          <a class="link-a" href="#" style={{textDecoration:'none',background:'#FA9823',color: 'white'}}> Read More</a>
        </div>
      </div>

      <div class="card">
        <img src="https://pibig.info/uploads/posts/2021-06/thumbs/1623572328_14-pibig_info-p-zimnie-gori-priroda-krasivo-foto-14.jpg" alt="Animated Card Hover Effect Html & CSS"/>
        <div class="overlay">
          <h1 class="text-h1" style={{color:'black'}}>the mountains</h1>
          <p class="text-p" style={{color:'black'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias dolores ex expedita quaerat a in et.
          </p>
          <a class="link-a" href="#" style={{textDecoration:'none',background:'#FA9823',color: 'white'}}> Read More</a>
        </div>
      </div>

      <div class="card">
        <img src="https://pibig.info/uploads/posts/2021-06/1623123014_3-pibig_info-p-znachenie-vodi-v-prirode-priroda-krasivo-f-3.jpg" alt="Animated Card Hover Effect Html & CSS"/>
        <div class="overlay">
          <h1 class="text-h1" style={{color:'black'}}>the mountains</h1>
          <p class="text-p" style={{color:'black'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias dolores ex expedita quaerat a in et.
          </p>
          <a class="link-a" href="#" style={{textDecoration:'none',background:'#FA9823',color: 'white'}}> Read More</a>
        </div>
      </div>

      <div class="card">
        <img src="https://sportishka.com/uploads/posts/2022-03/thumbs/1647169883_1-sportishka-com-p-prozrachnii-led-baikala-turizm-krasivo-fot-1.jpg" alt="Animated Card Hover Effect Html & CSS"/>
        <div class="overlay">
          <h1 class="text-h1" style={{color:'black'}}>the mountains</h1>
          <p class="text-p" style={{color:'black'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias dolores ex expedita quaerat a in et.
          </p>
          <a class="link-a" href="#" style={{textDecoration:'none',background:'#FA9823',color: 'white'}}> Read More</a>
        </div>
      </div>

      <div class="card">
        <img src="https://pibig.info/uploads/posts/2021-06/thumbs/1623572328_14-pibig_info-p-zimnie-gori-priroda-krasivo-foto-14.jpg" alt="Animated Card Hover Effect Html & CSS"/>
        <div class="overlay">
          <h1 class="text-h1" style={{color:'black'}}>the mountains</h1>
          <p class="text-p" style={{color:'black'}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias dolores ex expedita quaerat a in et.
          </p>
          <a class="link-a" href="#" style={{textDecoration:'none',background:'#FA9823',color: 'white'}}> Read More</a>
        </div>
      </div>
	  </div> */}

<div class="container">
      <div class="itemW" id="1">
        <img className='image' src="https://images.unsplash.com/photo-1508124780861-b1687f9a13e5?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f841d43a63c085e930aa5b6b33e89a9f&auto=format&fit=crop&w=1385&q=80" alt="" />
        <div class="text">
          <h3>PROJECT 1</h3>
          <p>Short Description</p>
        </div>
        <div class="button">Learn More</div>
      </div>

      <div class="itemW" id="2">
        <img className='image'  src="https://images.unsplash.com/photo-1496492352121-593138d42a61?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=3542849cc0459345e3aa82a90ae51a01&auto=format&fit=crop&w=1350&q=80" alt="" />
        <div class="text">
          <h3>PROJECT 2</h3>
          <p>Short Description</p>
        </div>
        <div class="button">Learn More</div>
      </div>

      <div class="itemW" id="3">
        <img className='image'  src="https://images.unsplash.com/photo-1515215676803-119c88d493cf?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=baf00747bfb9fe41ad9af8cf00dbebbf&auto=format&fit=crop&w=1350&q=80" alt="" />
        <div class="text">
            <h3>PROJECT 3</h3>
          <p>Short Description</p>
          </div>
          <div class="button">Learn More</div>
      </div>

      <div class="itemW" id="4">
        <img className='image'  src="https://images.unsplash.com/photo-1496939217462-7d42e9a74f0e?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=5e3be993a88feed38e4f5374ff3ba115&auto=format&fit=crop&w=1350&q=80g" alt="" />
        <div class="text">
          <h3>PROJECT 4</h3>
          <p>Short Description</p>
        </div>
        <div class="button">Learn More</div>
      </div>

      <div class="itemW" id="5">
        <img className='image'  src="https://images.unsplash.com/photo-1504875427817-937a8a12e167?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cd7f399ba43093ead08e7dbf5e083f09&auto=format&fit=crop&w=1434&q=80" alt="" />
        <div class="text">
          <h3>PROJECT 5</h3>
          <p>Short Description</p>
        </div>
        <div class="button">Learn More</div>
      </div>

      <div class="itemW" id="6">
        <img className='image'  src="https://images.unsplash.com/photo-1493962853295-0fd70327578a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f758b0379f730e73c03b6153aaad7cf1&auto=format&fit=crop&w=1350&q=80" alt="" />
        <div class="text">
          <h3>PROJECT 6</h3>
          <p>Short Description</p>
        </div>
        <div class="button">Learn More</div>
      </div>
  </div>

    
</div>
</div> 

{/* clients */}


  </div>
    )
}