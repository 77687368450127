import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'; 
import Home from './pages/home';
import Navbar from './pages/components/navbar';
import Footer from './pages/components/foorter';
import Services from './pages/services/services';
import Contact from './pages/contact';
import Construction from './pages/services/construction';
import Fabrication from './pages/services/fabrication';
import Safety from './pages/services/safety';
import Telecom from './pages/services/telecommunication';
import Manpower from './pages/services/manpower';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        {/* public pages  */}
        <Route exact path="/" element={<Home />} />

        <Route exact path="/Services" element={<Services />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/Construction" element={<Construction />} />
        <Route exact path="/Fabrication" element={<Fabrication />} />
        <Route exact path="/Safety" element={<Safety />} />
        <Route exact path="/Telecom" element={<Telecom />} />
        <Route exact path="/Manpower" element={<Manpower />} />




      </Routes>
      <Footer />
    </Router>
  </>
  );
}

export default App;
