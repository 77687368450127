import './contact.css'
export default function Contact(){
    return(
        <div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div class="container">
                <span class="big-circle"></span>
                <img src="img/shape.png" class="square" alt="" />
                <div class="form">
                    <div class="contact-info">
                    <h3 class="title">Let's get in touch</h3>
                    <p class="text" style={{margin:'0'}}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
                        dolorum adipisci recusandae praesentium dicta!
                    </p>

                    <div class="info">
                        <div class="information">
                        <i class="fas fa-map-marker-alt"></i>

                        <p style={{marginLeft:'15px',marginTop:'20px'}}>Building 2982, Post 23232, Jeddah
Kingdom of Saudi Arabia</p>
                        </div>

                        <div class="information">
                        <i class="fas fa-map-marker-alt"></i>

                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Building 6373, Post 24243, Makkah Kingdom of Saudi Arabia</p>
                        </div>

                                  <div class="information">
                        <i class="fas fa-map-marker-alt"></i>

                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Shamila Street Jubail, Post 10020,Kingdom of Saudi Arabia</p>
                        </div>
                        <div class="information">
                        <i class="fas fa-envelope"></i> 
                        <p style={{marginLeft:'15px',marginTop:'0px'}}>sales@jazaaco.com</p>
                        </div>
                        <div class="information">
                        <i class="fas fa-phone"></i>
                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Jeddah: +966 59 512 3032</p>
                        </div>

                        <div class="information">
                        <i class="fas fa-phone"></i>
                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Jeddah: +966 57 041 2032 </p>
                        </div>

                        <div class="information">
                        <i class="fas fa-phone"></i>
                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Jeddah: +966 50 685 6298</p>
                        </div>

                        <div class="information">
                        <i class="fas fa-phone"></i>
                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Mekkah: +966 56 357 8506</p>
                        </div>

                        <div class="information">
                        <i class="fas fa-phone"></i>
                        <p style={{marginLeft:'15px',marginTop:'0px'}}>Jubail: +966 50 685 6298</p>
                        </div>
                    </div>

                    <div class="social-media" style={{padding:'0'}}>
                        <p>Connect with us :</p>
                        <div class="social-icons">
                        <a href="#">
                            <i class="fab fa-facebook-f" color='black'></i>
                        </a>
                        <a href="#">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a href="#">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a href="#">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        </div>
                    </div>
                    </div>

                    <div class="contact-form">
                    <span class="circle one"></span>
                    <span class="circle two"></span>

                    <form action="index.html" autocomplete="off">
                        <h3 class="title">Contact us</h3>
                        <div class="input-container">
                        <input type="text" name="name" class="input" />
                        <label for="">Username</label>
                        <span>Username</span>
                        </div>
                        <div class="input-container">
                        <input type="email" name="email" class="input" />
                        <label for="">Email</label>
                        <span>Email</span>
                        </div>
                        <div class="input-container">
                        <input type="tel" name="phone" class="input" />
                        <label for="">Phone</label>
                        <span>Phone</span>
                        </div>
                        <div class="input-container textarea">
                        <textarea name="message" class="input"></textarea>
                        <label for="">Message</label>
                        <span>Message</span>
                        </div>
                        <input type="submit" value="Send" class="btn" />
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}