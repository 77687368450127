import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import borghir from '../../assets/borghir.png'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap'
import logo from '../../assets/logo.png'

const Navbar = () => {
        const nav = useNavigate()
        const [showNavbar, setShowNavbar] = useState(false)

        const handleShowNavbar = () => {
            setShowNavbar(!showNavbar)
        }
        
        function Home (){
            console.log('here')
            nav('/')
        }

  return (
    <div className='navvy' style={{background:`white`,backgroundRepeat:'no-repeat',backgroundPosition: 'center center',backgroundSize:'cover',display:'flex',width:'100%',position: 'sticky',top: '0', marginTop: '0',zIndex:'1000000000000000'}}>
      <nav className="navbar">
        <div className="conntainer">

            <span style={{flex:'50%',cursor:'pointer',justifyContent:'start', flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                <img style={{width: '90px', marginLeft: '15px'}} src={logo}  alt="Brand Logo" />
            </span>

          <div className="menu-icon" style={{width: '30px', marginRight: '15px'}} onClick={handleShowNavbar}>
            <img style={{width: '25px'}} src={borghir} alt="Hamburger Icon" />
          </div>

          <div className={`nav-elements  ${showNavbar && 'active'}`} style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ul>
            <li>
                        <NavLink className="nav-link hoverable"  to="/" style={{fontWeight: '300', marginRight:'15px',color:'grey'}}>
                            Home
                        </NavLink>
                    </li>

                    <li>
                        <NavLink className="nav-link hoverable" to="/Services" style={{fontWeight: '300', marginRight:'-10px',color:'grey'}}>
                           Services
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className="nav-link hoverable"  to="/Contact" style={{fontWeight: '300', marginRight:'15px',color:'grey'}}>
                            Get in Touch
                        </NavLink>
                    </li>
            </ul>
          </div>
        </div>
      </nav>

    </div>
  )
}

export default Navbar