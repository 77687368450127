import { useEffect } from "react"
import $ from 'jquery'
import Fab from '../../assets/fabrication.jpg'
import { useLocation } from "react-router-dom";


export default function Manpower(){
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the component mounts or when the location changes
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(()=>{
        $(document).ready(function () {
      
            //init scrolling parallax
            $(window).scroll(function(e){
                var scrolled = $(window).scrollTop();
                if(scrolled < 750){
                    parallax()
                }
            });
          
            //define parallax function
            function parallax(){
              var scrolled = $(window).scrollTop();
              $('#parallax').css('background-positionY',(scrolled * -0.5)+'px');
            };
            
          });
    },[])


    return(
        <div>
            <section class="section-background parallax-2">
            <h2>MANPOWER</h2>
        </section>
            <section class="text-bloc">
            <p>Since its humble beginnings, the well-known Jazaaco Group of Companies has advanced
            significantly. It is currently a household name in the manpower industries on a global
            scale. The organisation, which makes the most of its capabilities, provides a vast array of
            services and meets all the needs of the construction industry, including those for villas,
            high-rise buildings, hotels, hospitals, and supermarkets.</p>

            <p>Understanding consumer needs and ensuring that they were met on schedule helped the
            business build a large and expanding clientele. The business has built out a position for
            itself in the industry based on dependability and the quality of the products given to the
            countless customers. Lemar's management has created and put into place a quality
            management system in accordance with international standards as part of their ongoing
            quest of excellence.</p>
            </section>
        </div>
    )
}