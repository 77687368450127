import { useEffect } from "react"
import $ from 'jquery'
import Fab from '../../assets/fabrication.jpg'
import { useLocation } from "react-router-dom";


export default function Fabrication(){
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the component mounts or when the location changes
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(()=>{
        $(document).ready(function () {
      
            //init scrolling parallax
            $(window).scroll(function(e){
                var scrolled = $(window).scrollTop();
                if(scrolled < 750){
                    parallax()
                }
            });
          
            //define parallax function
            function parallax(){
              var scrolled = $(window).scrollTop();
              $('#parallax').css('background-positionY',(scrolled * -0.5)+'px');
            };
            
          });
    },[])


    return(
        <div>
            <section class="section-background parallax-1">
            <h2>STEEL FABRICATION</h2>
        </section>
            <section class="text-bloc">
            <p>Every year, our steel fabrication division supplies construction, manufacturing, and
            other businesses all over with hundreds of tonnes of structural steel projects of the
            highest quality. All of our utilitarian designs are constructed using the greatest
            materials and craftsmanship currently available in the market.</p>
            <p>One of the top companies in Saudi Arabia, we specialise in the design, engineering,
            fabrication, and supply of different types of structural steel, miscellaneous steel, pipe
            rack support structures, power plant structures, conveyor structures, process building
            structures, steel storage tanks, pressure vessels, heat exchanges, silos, hoppers spools,
            pipes, and related accessories by utilising the most cutting-edge/up-to-date robotic
            technology.</p>

            <p>Jazaaco facilities enable the highly effective production of huge structures in
            constrained timescales. On our property, steel is manufactured. Our property offers
            excellent transportation access features that enable us to provide our customers highly
            dependable and useful truck and barge shipping choices.</p>
            </section>
        </div>
    )
}