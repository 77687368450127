import { useEffect } from "react"
import $ from 'jquery'
import Fab from '../../assets/fabrication.jpg'
import { useLocation } from "react-router-dom";


export default function Telecom(){
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the component mounts or when the location changes
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(()=>{
        $(document).ready(function () {
      
            //init scrolling parallax
            $(window).scroll(function(e){
                var scrolled = $(window).scrollTop();
                if(scrolled < 750){
                    parallax()
                }
            });
          
            //define parallax function
            function parallax(){
              var scrolled = $(window).scrollTop();
              $('#parallax').css('background-positionY',(scrolled * -0.5)+'px');
            };
            
          });
    },[])


    return(
        <div>
            <section class="section-background parallax-4">
            <h2>TELECOMMUNICATION</h2>
        </section>
            <section class="text-bloc">
            <p>H.A.C.E is a valuable name in the Kingdom for providing all kind of telecom services such
            like:- Technical Site Surveys BTS Installation Huawei (3012series, 3900 series) Nokia Flexi
            Edge BTS IBS Sites RF GSM, 2GModernization, 3G, 4G Microwave Huawei RTN 900 ,600
            Series, NEC, Sral etc Power Rectifiers(Power Once, Delta, Emerson, Huawei Power Cube ),
            IBBS installation Transmission, SDH, PDH, Fiber. </p>


            </section>
        </div>
    )
}