import { useEffect } from "react"
import $ from 'jquery'
import { useLocation } from "react-router-dom";

export default function Construction(){

    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the component mounts or when the location changes
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(()=>{
        $(document).ready(function () {
      
            //init scrolling parallax
            $(window).scroll(function(e){
                var scrolled = $(window).scrollTop();
                if(scrolled < 750){
                    parallax()
                }
            });
          
            //define parallax function
            function parallax(){
              var scrolled = $(window).scrollTop();
              $('#parallax').css('background-positionY',(scrolled * -0.5)+'px');
            };
            
          });
    },[])


    return(
        <div>
            <section class="section-background parallax">
            <h1>CONSTRUCTION</h1>
        </section>
            <section class="text-bloc">
            <p>Jazaaco is involved in significant renovation construction projects, rehabilitation and
            maintenance. Jazaaco strives to continue meeting clients' needs for high-end living
            environments that are harmonious with their surroundings by utilising our cutting-edge
            building experience and technologies. We provide a comprehensive range of
            dependable construction solutions for the provision of sites, efficient land use, and
            effective project execution.</p>
            <p>Jazaaco is a creative company made up of a creative team of design experts who offer
            top-notch services in landscape architecture, urban planning, and urban design, from
            the first stages of visioning through all phases of design and delivery. Innovation is a way
            of life at Jazaaco. In the built and natural environments, we are uniquely equipped with
            end-to-end services to take clients from inspiration through conceptualization to
            project implementation. We have taken the lead with vision, supporting some of the
            most ambitious projects, famous locations, unforgettable experiences, and thriving
            neighbourhoods</p>
            </section>
        </div>
    )
}