import { useEffect } from "react"
import $ from 'jquery'
import Fab from '../../assets/fabrication.jpg'
import { useLocation } from "react-router-dom";


export default function Safety(){
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the component mounts or when the location changes
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(()=>{
        $(document).ready(function () {
      
            //init scrolling parallax
            $(window).scroll(function(e){
                var scrolled = $(window).scrollTop();
                if(scrolled < 750){
                    parallax()
                }
            });
          
            //define parallax function
            function parallax(){
              var scrolled = $(window).scrollTop();
              $('#parallax').css('background-positionY',(scrolled * -0.5)+'px');
            };
            
          });
    },[])


    return(
        <div>
            <section class="section-background parallax-3">
            <h2>SAFETY</h2>
        </section>
            <section class="text-bloc">
            <p>Our safety philosophy holds that any job or project that is successful may be directly
            related to great performance and thoughtfully planned services. We have an unequalled
            safety record because we stay away from shoddy workmanship and mistakes brought on
            by hurrying, stress, and bad working conditions. Since human mistake is the primary
            cause of accidents, reducing or eliminating these circumstances will lead to fewer a
            ccidents. </p>

            <p>Jazaaco management is dedicated to the ongoing creation and enhancement of safety
            programmes to safeguard our employees on all job sites. We also recognise that our
            employees and professionals are our most valuable resources. We continue to strike to
            make our facilities and job places safer because we are proud of our safety record. We shall
            never jeopardise the security of our workers, tools, or clients.</p>
            </section>
        </div>
    )
}